<template>
  <div class="row mx-0">
    <div class="col-12 p-0" v-if="organisations && organisations.length > 0">
      <!-- TABLE HEADING -->
      <div class="row m-0">
        <!--MOBILE-->
        <div class="col-12 d-md-none px-2">
          <div class="row font13 green-text-dark">
            <div class="col-8 bold text-center px-1">
              Name
            </div>
            <!-- <div class="col bold text-center px-1">
              Date
            </div> -->
            <div class="col bold text-center px-1">
              Info
            </div>
          </div>
        </div>
        <!--END MOBILE-->
        <!--DESKTOP-->
        <div class="col-12 d-none d-md-block">
          <div class="row font13 green-text-dark">
            <div class="col bold text-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3 rounded-left">
                ORGANISATION NAME
              </div>
            </div>
            <div class="col bold text-md-center px-0 d-none d-md-flex">
              <div class="lightgrey_bg w-100 h-100 p-3">
                ORGANISATION ADMIN
              </div>
            </div>
            <!-- <div class="col bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3">
                JOINED
              </div>
            </div> -->
            <div class="col bold text-md-center px-1 d-none d-md-flex">
              <div class="lightgrey_bg w-100 h-100 p-3">
                DONATIONS
              </div>
            </div>
            <div class="col-3 bold text-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3 rounded-right">
                INFO
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END TABLE HEADING -->
      <OrganisationItem v-for="(organisation, key) in organisations.slice(start, finish)" :key="key" :item="organisation" :thekey="key" @viewDonations="viewDonations(organisation.organisationID)" @editOrg="editOrg(organisation.organisationID)" @deleteOrg="deleteOrg(key)" />
      <!-- PAGINATION -->
      <div class="row mx-0 mt-4" v-if="totalPages > 1">
        <div class="col-12 text-center">
          <div class="d-inline p-2 cursor" v-for="index in totalPages" :key="index" @click="goToPage(index)" :class="{bold: currentPage === index - 1}">
            {{ index }}
          </div>
        </div>
      </div>
      <!-- END PAGINATION -->
    </div>
    <div class="col-12" v-else>
      <div class="row mx-0 my-3 justify-content-center align-items-center" v-if="organisationsStatus !== 'ready'">
        <div class="col-auto ps-0">
          {{organisationsStatus}} Organisations
        </div>
        <div class="col-auto p-0">
          <div class="spinner-border spinner-border-sm green-text" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div class="m-3 text-center" v-else>
        You don't manage any organisations, try adding an organisation.
      </div>
    </div>
    <transition name="fade">
      <Popup @close="isDeleteOrg = false" v-if="isDeleteOrg">
        <div class="row mx-0 justify-content-center">
          <div class="col-12 text-center mb-3 green-text bold font19">
            Delete Organisation
          </div>
          <div class="col-12 text-center mb-3">
            Are you sure you would like to delete {{currentItem.registeredName}}
          </div>
          <div class="col-6 px-1 text-center">
            <Button color="grey" btnText="Keep Organisation" width="100" @buttonClicked="closeDelete" />
          </div>
          <div class="col-6 px-1 text-center">
            <Button color="red" btnText="Delete Organisation" width="100" @buttonClicked="reallyDeleteOrg(currentItem.organisationID)" />
          </div>
        </div>
      </Popup>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    OrganisationItem: defineAsyncComponent(() => import('./OrganisationItem.vue')),
    Popup: defineAsyncComponent(() => import('@/views/Popup.vue'))
  },
  name: 'Organisation Table',
  data () {
    return {
      itemsPerPage: 7,
      currentPage: 0,
      currentItem: null,
      currentKey: null,
      isEditGroupName: false,
      isViewGroupMembers: false,
      isAddNewMember: false,
      newMember: null,
      newMemberStatus: '',
      isDeleteOrg: false
    }
  },
  watch: {
    transactionSearch () {
      this.currentPage = 0
    }
  },
  computed: {
    ...mapGetters(['user', 'organisations', 'organisationAdmin', 'organisationsStatus']),
    totalPages () {
      return Math.ceil(this.organisations.length / this.itemsPerPage)
    },
    start () {
      return this.currentPage * this.itemsPerPage
    },
    finish () {
      return this.start + this.itemsPerPage
    }
  },
  methods: {
    ...mapActions(['deleteOrganisation']),
    goToPage (val) {
      this.currentPage = val - 1
    },
    editOrg (val) {
      this.currentItem = this.organisations[val]
      window.scrollTo(0, 0)
      this.$router.push('/organisationedit/' + val)
    },
    deleteOrg (val) {
      this.isDeleteOrg = true
      this.currentItem = this.organisations[val]
    },
    async reallyDeleteOrg (val) {
      await this.deleteOrganisation(val)
      this.closeDelete()
    },
    closeDelete () {
      this.isDeleteOrg = false
      this.closeDetails()
    },
    sendInvite () {
      this.newMemberStatus = 'notfound'
    },
    viewDonations (val) {
      window.scrollTo(0, 0)
      this.$router.push('/organisationdonations/' + val)
    },
    goForward (val) {
      this.currentItem = this.organisations[val + 1]
      this.currentKey = val + 1
    },
    goBack (val) {
      this.currentItem = this.organisations[val - 1]
      this.currentKey = val - 1
    },
    closeDetails () {
      this.isViewGroupMembers = false
      this.currentItem = null
      this.currentKey = null
    }
  }
}
</script>

<style scoped>
.rounded-left {
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
}
.rounded-right {
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}
@media (min-width: 992px) {
  .rounded-left {
    border-bottom-left-radius: 100px;
    border-top-left-radius: 100px;
  }
  .rounded-right {
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
  }
}
.slideup-enter-active,
.slideup-leave-active {
  transition: all 0.5s ease;
}

.slideup-enter-from,
.slideup-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
</style>
